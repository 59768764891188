import Link from 'next/link';
import React from 'react';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

export default function CustomLink(props) {
  const { href, children, onClick } = props;
  const onLinkClick = (event) => {
    logEvent(EVENTS.CLICK, {
      action: 'Link clicked',
      label: `${href}`,
    });

    if (onClick) onClick(event);
  };
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Link
      onClick={onLinkClick}
      onKeyDown={(e) => e.key === 'Enter' && onLinkClick()}
      {...props}
    >
      {children}
    </Link>
  );
}
