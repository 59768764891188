import Image from 'next/image';
import AppleIcon from 'public/images/icons/icon-apple.svg';
import GoogleIcon from 'public/images/icons/icon-google.svg';
import LoadingIcon from 'public/images/loading.svg';
import SpinIcon from 'public/images/tail-spin.svg';
import React from 'react';

export function GoogleButton({ loading, onClick }) {
  return (
    <button
      className="bg-white text-slate-800 px-12 py-4 cursor-pointer rounded-full w-60 border flex flex-row items-center justify-center"
      type="button"
      onClick={onClick}
    >
      <Image
        src={GoogleIcon}
        alt="Google"
        width="40"
        height="20"
        priority
        className="h-[20px]"
      />
      Google
      {loading && (
        <Image
          src={LoadingIcon}
          className="h-[20px]"
          alt="Loading"
          width="40"
          height="20"
          priority
        />
      )}
    </button>
  );
}

export function FacebookButton({ loading, onClick }) {
  return (
    <button
      className="bg-[#006aff] text-white px-12 py-4 cursor-pointer rounded-full w-60 border flex items-center justify-center"
      type="button"
      onClick={onClick}
    >
      <Image
        src="/images/icons/icon-facebook.svg"
        className="h-[20px]"
        alt="Facebook"
        width="40"
        height="20"
        priority
      />
      Facebook
      {loading && (
        <Image
          src={SpinIcon}
          className="h-[20px]"
          alt="Loading"
          width="40"
          height="20"
        />
      )}
    </button>
  );
}

export function AppleButton({ loading, onClick }) {
  return (
    <button
      className="bg-black text-white px-12 py-4 cursor-pointer rounded-full w-60 border flex items-center justify-center"
      type="button"
      onClick={onClick}
    >
      <Image
        src={AppleIcon}
        alt="Apple"
        width="40"
        height="20"
        priority
        className="h-[20px]"
      />
      Apple
      {loading && (
        <Image
          src={SpinIcon}
          className="h-[20px]"
          alt="Loading"
          width="40"
          height="20"
        />
      )}
    </button>
  );
}
