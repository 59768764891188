import Link from 'components/common/CustomLink';
import useLoginModal from 'hooks/auth/useLoginModal';
import { SIGNIN_SIGNUP } from 'hooks/auth/userSource';
import React, { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { LOCATIONS } from 'utils/constants/locations';

import AvatarMenu from './AvatarMenu';

export function AvatarLoading({ onClick = () => {} }) {
  return (
    <div
      className="h-14 w-14 rounded-full bg-gray-300 animate-pulse"
      onClick={() => onClick()}
      aria-hidden="true"
    >
      &nbsp;
    </div>
  );
}

export function SignInButton({ location }) {
  const { showLoginModal } = useLoginModal();

  const onClick = (e) => {
    e.preventDefault();
    showLoginModal(SIGNIN_SIGNUP, { key: 'avatar', location });
  };

  return (
    <Link
      id="avatar-btn"
      className="inline-flex items-center px-1 sm:px-6 bg-purple-600 text-white text-xs sm:text-sm h-12 rounded-full cursor-pointer whitespace-nowrap"
      onClick={onClick}
      href="/login"
    >
      Sign Up
    </Link>
  );
}

function getInitialsFromUser(user) {
  let output = '?';
  if (user === null) return output;

  const { first_name: firstName, last_name: lastName, email } = user ?? {};
  if (firstName && lastName) {
    output = `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`;
  } else if (firstName) {
    output = `${firstName?.slice(0, 1)}`;
  } else {
    output = email?.slice(0, 1);
  }
  return output;
}

export default function Avatar({ auth, showMenu = false, onClick = () => {} }) {
  const [initials, setInitials] = useState('');
  const [menuEligible, setMenuEligible] = useState(false);

  useEffect(() => {
    if (auth?.user) {
      setInitials(getInitialsFromUser(auth?.user));
      setMenuEligible(true);
    }
  }, [auth]);

  if (!auth?.loggedIn) return <SignInButton location={LOCATIONS.HEADER} />;

  return (
    <FocusLock className="relative" disabled={!showMenu}>
      <button
        id="avatar-btn"
        type="button"
        className="h-14 w-14 rounded-full grid bg-purple-600 text-white text-light place-items-center cursor-pointer select-none uppercase"
        onClick={() => onClick()}
        aria-label="User profile"
        aria-expanded={showMenu}
      >
        {initials}
      </button>
      {menuEligible ? <AvatarMenu show={showMenu} /> : null}
    </FocusLock>
  );
}
