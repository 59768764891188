import { AvatarLoading } from 'components/common/Avatar/Avatar';
import Link from 'components/common/CustomLink';
import { useAuth } from 'hooks/auth/useAuth';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import MobileLogo from 'public/images/logos/logo-mobile.svg';
import WideLogo from 'public/images/logos/logo-wide.svg';
import React, { useEffect, useRef, useState } from 'react';

const Avatar = dynamic(() => import('components/common/Avatar/Avatar'), {
  loading: () => <AvatarLoading />,
});

const LocationSearchInput = dynamic(() =>
  import('components/form/inputs/LocationSearchInput/LocationSearchInput'),
);

export default function Header({ hideSearch, primary = true }) {
  const auth = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const avatarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (avatarRef.current && !avatarRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full bg-white border-t border-b border-slate-200 sticky top-0 z-30">
      <div className="lg:container mx-auto flex flex-row justify-between w-full h-20 items-center p-2">
        <div className="cursor-pointer block sm:hidden md:hidden lg:hidden">
          <Link href="/">
            <Image
              src={MobileLogo}
              alt="Dwellsy"
              width={50}
              height={50}
              className="w-[50px] h-[50px]"
            />
          </Link>
        </div>
        <div className="cursor-pointer lg:min-w-[160px] hidden sm:block md:block lg:block">
          <Link href="/">
            <Image
              src={WideLogo}
              alt="Dwellsy"
              width={156}
              height={40}
              className="h-[40px] w-[156px]"
            />
          </Link>
        </div>
        <div className="w-full md:w-auto ">
          <div className="w-full md:w-auto pl-2 flex flex-row justify-center lg:w-[600px]">
            {hideSearch ? null : (
              <LocationSearchInput
                truncateSize="w-[160px] md:w-full"
                resetOverflow={primary}
                header
              />
            )}
          </div>
        </div>
        <div
          className="justify-self-end lg:min-w-[160px] justify-end flex"
          ref={avatarRef}
        >
          <Avatar auth={auth} onClick={toggleMenu} showMenu={showMenu} />
        </div>
      </div>
    </div>
  );
}
