import Image from 'next/image';
import DwellsyModalLogo from 'public/images/dwellsymodallogo.png';
import DwellsyEdgeLogo from 'public/images/logos/dwellsy-edge.svg';
import React from 'react';

// Covers all 'login' modal bodies - https://zpl.io/DlRnJXl
// Also covers some of 'inquiry flow' modals- https://zpl.io/wq91ovj
function TopRightClose({ closeModal, children, showDwellsyLogo = 'dwellsy' }) {
  return (
    <div
      className="p-1 w-full"
      tabIndex="-1"
      role="button"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) =>
        (e.key === 'Escape' && closeModal()) || e.stopPropagation()
      }
    >
      <div className="flex justify-between">
        <button
          type="button"
          aria-label="Close modal"
          className=" border items-center w-11 relative top-2 right-2 aspect-square rounded-full text-sm ml-auto flex justify-center"
          onClick={closeModal}
          onKeyDown={(e) => e.key !== 'Tab' && closeModal(e)}
        >
          <svg
            aria-hidden="true"
            className="w-4 h-4 relative"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          {/* <span>Close</span> */}
        </button>
      </div>
      <div className="flex flex-col justify-center space-y-3 items-center w-full">
        {showDwellsyLogo && (
          <Image
            src={
              showDwellsyLogo === 'edge' ? DwellsyEdgeLogo : DwellsyModalLogo
            }
            alt="Dwellsy Logo"
            height={showDwellsyLogo === 'edge' ? 80 : 50}
          />
        )}

        {children}
      </div>
    </div>
  );
}

export default TopRightClose;
