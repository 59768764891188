import React, { useEffect } from 'react';

function ModalCenteredFrame({ blurModal, children }) {
  useEffect(() => {
    const bodyOverflow = document.body.style.overflow ?? 'unset';
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = bodyOverflow;
    };
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex top-0 left-0 w-full h-full overflow-y-scroll fixed backdrop-blur-sm backdrop-brightness-50 z-40"
      onClick={(e) => e.preventDefault()}
      onKeyDown={blurModal}
    >
      <div
        className="overflow-x-hidden w-full max-w-[450px] z-50 m-auto"
        id="mainModal"
        aria-labelledby="mainModal"
        aria-hidden="true"
      >
        <div className="min-h-[400px] p-2 w-full bg-white max-w-[450px] rounded-xl md:h-auto">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalCenteredFrame;
