import TopRightClose from 'components/containers/Modal/Bodies/TopRightClose';
import ModalCenteredFrame from 'components/containers/Modal/Frames/ModalCenteredFrame';
import LoginForm from 'components/form/LoginForm/LoginForm';
import { useAuth } from 'hooks/auth/useAuth';
import { EDGE_SIGNUP, SIGNIN_SIGNUP } from 'hooks/auth/userSource';
import { ModalContext, useModal } from 'hooks/modal';
import React, { useContext } from 'react';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

function ModalComponent({ blurModal, closeModal, loggedIn }) {
  const context = useContext(ModalContext);

  if (loggedIn) closeModal();

  const handleOnLogin = () => {
    logEvent(EVENTS.SIGN_UP_SUCCESS, {
      buttonSource: context?.loginOptions?.userSource,
      location: context?.loginOptions?.actionContext?.location,
      // authType: context doesn't bring the authType, leaving this out for now, ideally should be added
    });

    const actionKey = context.loginOptions.actionContext?.key;
    // call login callback only if action key is not set or callback key = action key
    if (actionKey) {
      const callback = context.loginOptions.callbacks[actionKey];
      if (typeof callback === 'function') {
        callback({ ...context.loginOptions.actionContext });
        // set action key to non existing key in order a callback to be run only once
        context.loginOptions.actionContext.key = Symbol('non-existing-key');
        localStorage.removeItem('loginAction');
      }
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of Object.values(context.loginOptions.callbacks)) {
        cb(context.loginOptions?.actionContext);
      }
      // set action key to non existing key in order a callback to be run only once
      context.loginOptions.actionContext.key = Symbol('non-existing-key');
      localStorage.removeItem('loginAction');
    }
  };

  const dwellsyLogo =
    context.loginOptions.userSource === EDGE_SIGNUP ? 'edge' : 'dwellsy';

  return (
    <ModalCenteredFrame>
      <TopRightClose closeModal={closeModal} showDwellsyLogo={dwellsyLogo}>
        <LoginForm
          userSource={context?.loginOptions?.userSource}
          onLogin={handleOnLogin}
          onClose={closeModal}
          location={context?.loginOptions?.actionContext?.location}
        />
      </TopRightClose>
    </ModalCenteredFrame>
  );
}

/**
 *
 * @param key A unique key that defines where the LoginModal is shown.
 * @param onLogin Action that should be performed on successful Login
 * @returns {{showLoginModal: (function(string=, {}=): void)}}
 */
export default function useLoginModal(key, onLogin) {
  const context = useContext(ModalContext);
  const { loggedIn } = useAuth();
  const [showModal] = useModal((props) => (
    <ModalComponent
      blurModal={props.blurModal}
      closeModal={props.closeModal}
      loggedIn={loggedIn}
    />
  ));

  if (!context.loginOptions) {
    const actionContext =
      typeof window !== 'undefined'
        ? JSON.parse(localStorage.getItem('loginAction')) ?? {}
        : {};

    context.loginOptions = {
      actionContext,
      callbacks: {},
    };
  }

  if (key && onLogin) {
    context.loginOptions.callbacks[key] = onLogin;
  }

  // run onLogin callback if it didn't run on actual onLogin event
  // (in case the page didn't loaded on time)
  if (
    key &&
    key === context.loginOptions.actionContext?.key &&
    onLogin &&
    loggedIn
  ) {
    // add a small delay to be sure that page is loaded
    setTimeout(onLogin(context.loginOptions.actionContext), 700);
    // set action key to non-existing key in order a callback to be run only once
    context.loginOptions.actionContext.key = Symbol('non-existing-key');
    localStorage.removeItem('loginAction');
  }

  /**
   *
   * @param userSource Signup User source name
   * @param actionContext Action Context must contain key that defines where login is shown,
   *                      in other case all onLogin actions that were currently defined
   *                      will be executed
   */
  const showLoginModal = (userSource = SIGNIN_SIGNUP, actionContext = {}) => {
    // skip updating loginOptions if called with null context
    // it's used on oAuth page for displaying this modal without updating context
    if (actionContext !== null) {
      context.loginOptions.userSource = userSource;
      context.loginOptions.actionContext = actionContext;
      localStorage.setItem('loginAction', JSON.stringify(actionContext));
    }

    logEvent(EVENTS.SIGN_UP_INITIATE, {
      buttonSource: context?.loginOptions?.userSource,
      location: context?.loginOptions?.actionContext?.location,
    });

    return showModal();
  };

  return { showLoginModal };
}
