import { useFeature } from '@growthbook/growthbook-react';
import { Progressbar } from 'components/account/ProfileProgressHero';
import {
  AppleButton,
  FacebookButton,
  GoogleButton,
} from 'components/common/Buttons/OAuthButtons';
import Link from 'components/common/CustomLink';
import { useAuth } from 'hooks/auth/useAuth';
import { EDGE_SIGNUP, SIGNIN_SIGNUP } from 'hooks/auth/userSource';
import Image from 'next/image';
import CornerImageSrc from 'public/images/paperfold-purple.svg';
import React, { useEffect, useRef, useState } from 'react';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

function LogInComplete({ onClose }) {
  const auth = useAuth();
  const modalConfig = useFeature('loginmodaltitle').value;

  const handleClose = (e) => {
    if (onClose) {
      e.preventDefault();
      onClose(e);
    }
  };

  const title =
    (auth.user?.login_count > 1
      ? modalConfig?.completeTitleExistingUser
      : modalConfig?.completeTitleNewUser) || 'Login complete';
  const message =
    (auth.user?.login_count > 1
      ? modalConfig?.completeTextExistingUser
      : modalConfig?.completeTextNewUser) || 'Welcome to Dwellsy';

  return (
    <div className="mx-auto w-full min-h-[590px] flex flex-col justify-center items-center text-center">
      <h1 className="text-2xl mt-4">
        {' '}
        {message.replace(
          '{name}',
          `${auth?.user?.first_name ?? ''} ${auth?.user?.last_name ?? ''}`,
        )}
      </h1>
      <p className="mx-auto max-w-sm">{title}</p>
      <div className="flex flex-1 flex-col">
        <Image
          src="/images/login-complete.svg"
          alt="Dwellsy Logo"
          width={200}
          height={200}
        />
      </div>
      <div className="w-full flex flex-col py-3 justify-center relative items-center content-center rounded-2xl bg-[#F2F1FF]">
        <h2 className="text-[13px] font-bold text-[#562AE2]">
          {modalConfig?.completeTitleBottom ?? 'Optimize your search'}
        </h2>
        <p className="font-base text-center mb-4 text-[#562AE2]">
          {modalConfig?.completeTextBottom ??
            'Take a few seconds to tell us about yourself'}
        </p>
        <div className="w-2/3 h-10 my-2">
          <Progressbar
            progress={auth?.user?.profile_completeness ?? 0}
            showMessage={false}
          />
        </div>
        <Link
          className="bg-primary-purple text-white text-sm px-12 py-4 cursor-pointer rounded-full"
          href="/account"
          onClick={onClose}
        >
          Complete profile
        </Link>{' '}
        <div className="w-full flex flex-row justify-end">
          <Image
            src={CornerImageSrc}
            alt="Paperfold corner"
            width={28}
            height={28}
            className="absolute bottom-0 right-0"
          />
        </div>
      </div>

      <Link
        className="text-primary-purple mt-6 font-semibold text-sm cursor-pointer mb-4"
        onClick={handleClose}
        href="/"
      >
        No thanks, I&apos;m just checking things out
      </Link>
    </div>
  );
}

const RESEND_INTERVAL = 15;

function Verify() {
  const ref = useRef();
  const auth = useAuth();
  const [verifyCode, setVerifyCode] = useState(null);
  const [error, setError] = useState(null);
  const [resendTimer, senResendTimer] = useState(RESEND_INTERVAL);
  const modalConfig = useFeature('loginmodaltitle').value;

  useEffect(() => {
    const interval = setInterval(
      () => senResendTimer((value) => (value > 0 ? value - 1 : 0)),
      1000,
    );
    return () => clearInterval(interval);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const x = ref.current.reportValidity();
    setError(null);
    if (x)
      auth
        .verify(verifyCode, auth.verifyToken)
        .catch((err) => setError(err.message));
  };

  const resend = () => {
    senResendTimer(RESEND_INTERVAL);
    auth.submitEmail(auth.user.email).catch((e) => setError(e.message));
  };

  const resendColor =
    resendTimer === 0 ? 'text-primary-purple' : 'text-gray-400';
  return (
    <div className="text-center min-h-[590px] flex flex-col justify-center">
      <h1 className="text-2xl mt-4">
        {modalConfig?.verifyTitle || 'Verify your email'}
      </h1>
      <p className="mx-auto max-w-sm my-4">
        {(
          modalConfig?.verifyText ||
          'Please enter the 6 digit code we sent to {email}'
        ).replace('{email}', auth.user.email)}
      </p>
      <form ref={ref}>
        <div className="flex flex-col gap-y-4 items-center">
          <input
            type="text"
            required
            placeholder="Verification code"
            onChange={(e) => setVerifyCode(e.currentTarget.value)}
            className="border border-gray-400 py-3 px-6 rounded-md text-center"
          />
          {error ? (
            <div className="-mt-3 text-xs text-red-600">{error}</div>
          ) : null}
          <button
            className="bg-primary-purple text-white px-12 py-4 cursor-pointer rounded-full w-60"
            onClick={onSubmit}
            type="button"
          >
            Continue
          </button>
          <button
            className={`${resendColor} px-12 py-4 cursor-pointer`}
            onClick={resend}
            type="button"
            disabled={resendTimer > 0}
          >
            Resend Code{resendTimer ? ` ${resendTimer}s` : ''}
          </button>
        </div>
      </form>
      <div className="mt-6 text-sm" aria-hidden="true">
        Incorrect email?{' '}
        <button
          className="text-primary-purple mt-6 font-semibold text-sm cursor-pointer"
          onClick={() => auth.resetEmail()}
          type="button"
        >
          Click here
        </button>{' '}
        to start over
      </div>
    </div>
  );
}

export default function LoginForm({
  onLogin,
  onClose = () => {},
  userSource = SIGNIN_SIGNUP,
  location,
}) {
  const ref = useRef();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const auth = useAuth();
  const socialLogins = useFeature('socialloginsenabled').value;
  const modalCofigKey =
    userSource === EDGE_SIGNUP ? 'edgeloginmodaltitle' : 'loginmodaltitle';
  const modalConfig = useFeature(modalCofigKey).value;
  const showConnectWithText =
    !socialLogins ||
    socialLogins?.apple ||
    socialLogins?.facebook ||
    socialLogins?.google;

  const onChange = (e) => {
    e.preventDefault();
    setEmail(e.currentTarget.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const x = ref.current.reportValidity();
    setError(null);
    if (x) {
      logEvent(EVENTS.SIGN_UP_CONTINUE, {
        buttonSource: userSource,
        location,
        authType: 'email',
      });
      auth.submitEmail(email, userSource).catch((err) => setError(err.message));
    }
  };

  useEffect(() => {
    if (auth.loggedIn && onLogin) {
      onLogin();
    }
    // eslint-disable-next-line
  }, [auth.loggedIn]);

  if (auth.user !== null && auth.loggedIn)
    if (auth?.user?.profile_completeness !== 100)
      return <LogInComplete onClose={onClose} />;
    else onClose();
  if (auth.user !== null && !auth.loggedIn && auth.verifyToken)
    return <Verify />;

  return (
    <div className="text-center w-full min-h-[590px] flex flex-col justify-center">
      <h1 className="text-2xl mt-4">
        {modalConfig?.loginTitle || 'Welcome to Dwellsy'}
      </h1>
      <p className="mx-auto max-w-sm my-4">
        {modalConfig?.loginText ||
          'Your FREE Dwellsy account allows you to save favorites, manage inquiries and more. Enter your email to get started.'}
      </p>
      <form onSubmit={onSubmit} ref={ref}>
        <div className="flex flex-col gap-y-4 items-center">
          <input
            data-autofocus
            type="email"
            required
            placeholder="Email address"
            onChange={(e) => onChange(e)}
            className="border border-gray-400 py-3 px-6 rounded-md w-80 text-center"
          />
          {error ? (
            <div className="-mt-3 text-xs text-red-600">{error}</div>
          ) : null}
          <button
            className="bg-primary-purple text-white px-12 py-4 cursor-pointer rounded-full w-60"
            onClick={onSubmit}
            type="button"
          >
            Continue
          </button>
        </div>
      </form>
      <div className="mt-4">
        {showConnectWithText ? (
          <div className="my-4 text-center">Or connect with:</div>
        ) : null}
        <div className="grid grid-cols-1 gap-y-4 mb-4 justify-items-center">
          {!socialLogins || socialLogins.apple ? (
            <AppleButton
              onClick={() => auth.handleOAuth('apple', userSource)}
              loading={auth?.oAuth?.type === 'apple'}
            />
          ) : null}
          {!socialLogins || socialLogins.facebook ? (
            <FacebookButton
              onClick={() => auth.handleOAuth('facebook', userSource)}
              loading={auth?.oAuth?.type === 'facebook'}
            />
          ) : null}
          {!socialLogins || socialLogins.google ? (
            <GoogleButton
              onClick={() => auth.handleOAuth('google', userSource)}
              loading={auth?.oAuth?.type === 'google'}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
