import React from 'react';

const selectBackgroundColorFromProgress = (progress) => {
  if (progress < 20) return `from-orange-100 to-orange-600`;
  if (progress > 19 && progress < 40) return `from-yellow-300 to-orange-300`;
  if (progress > 39 && progress < 60) return `from-pink-100 to-yellow-300`;
  if (progress > 59 && progress < 80) return `from-pink-500  to-pink-500`;
  if (progress > 79 && progress < 99) return `from-green-200 to-green-400`;
  return `from-green-400 to-green-600`;
};

const message = (percent) => {
  if (percent >= 100) {
    return 'Congratulations! Your profile is complete.';
  }
  if (percent >= 80) {
    return 'Your profile is nearly there!';
  }
  if (percent >= 40) {
    return 'Your profile is getting close!';
  }
  return 'Complete your profile to get best search results!';
};

export function Progressbar({
  progress,
  progressBelowBar = true,
  fontColor = 'text-white',
  bgColor = null,
  height = 'h-4',
}) {
  // const clampedProgress = Math.max(11, Math.min(85, progress));
  const barColor = bgColor ?? selectBackgroundColorFromProgress(progress);
  return (
    <div
      className={`border w-full bg-white rounded-full ${height} p-0.5 border-primary-purple `}
    >
      <div
        className={`bg-gradient-to-r ${barColor} h-full rounded-full flex justify-center items-center text-black font-semibold text-[13px]`}
        style={{ width: `${Math.max(11, progress)}%` }}
      >
        {progressBelowBar ? ' ' : `${progress}%`}
      </div>
      {progressBelowBar && (
        <div
          className={`flex flex-col mt-1 ${fontColor} w-full justify-center items-center`}
          // style={{ marginLeft: `${clampedProgress - 10}%` }}
        >
          <div className="text-primary-purple font-semibold text-sm md:text-sm w-full text-center">
            {progress}% Complete
          </div>
        </div>
      )}
    </div>
  );
}

export default function ProfileProgressHero({
  progress,
  className = '',
  showMessage = true,
  progressColorFunction = selectBackgroundColorFromProgress,
}) {
  const bgColor = progressColorFunction(progress);
  return (
    <div
      className={`flex flex-col justify-center items-center mx-auto ${className}`}
    >
      {showMessage && (
        <div className="text-xl text-blue-900 font-semibold my-2 text-center">
          {message(progress)}
        </div>
      )}
      <Progressbar
        progress={progress}
        bgColor={bgColor}
        progressBelowBar={false}
        height="h-5"
      />
    </div>
  );
}
