// READ THIS BEFORE CREATING A NEW EVENT
// Events cannot contain hyphens and may ONLY contain letters,
// numbers, and underscores.
// READ THIS BEFORE CREATING A NEW EVENT

export const LOCATIONS = {
  HEADER: 'header',
  LOGIN_PAGE: 'login_page',

  PROPERTY_CARD_SEARCH_RESULTS: 'property_card_search_results',
  PROPERTY_CARD_FAVORITE_LIST: 'property_card_favorite_list',
  PROPERTY_CARD_MAP_PREVIEW: 'property_card_map_preview',
  PROPERTY_CARD_EXPLORE_NEARBY: 'property_card_explore_nearby',

  PROPERTY_DETAILS_PAGE: 'property_details_page',
  PROPERTY_DETAILS_DESCRIPTION_GATING: 'property_details_description_gating',
  PROPERTY_DETAILS_FLOATING_ACTION_PANEL:
    'property_details_floating_action_panel',
  PROPERTY_DETAILS_COMP_REPORT: 'property_details_comp_report',
  PROPERTY_DETAILS_AMENITY_LIKING: 'property_details_amenity_liking',

  PROPERTY_ADDRESS_PAGE: 'property_address_page',

  GET_SUPPORT_REQUEST_FEATURES: 'get_support_request_features',
  GET_SUPPORT_REPORT_ISSUE: 'get_support_report_issue',
  GET_SUPPORT_REPORT_A_LISTING: 'get_support_report_a_listing',
  GET_SUPPORT_TELL_US_YOUR_STORY: 'get_support_tell_us_your_story',

  FEEDBACK_PAGE_GATING: 'feedback_page_gating',
  REPORT_FRAUD_PAGE_GATING: 'report_fraud_page_gating',

  DWELLSY_EDGE_EARLY_ACCESS_CARD: 'dwellsy_edge_early_access_card',
  DWELLSY_EDGE_SAFE_SEARCH_CARD: 'dwellsy_edge_safe_search_card',
  DWELLSY_EDGE_MOVING_CARD: 'dwellsy_edge_moving_card',
  DWELLSY_EDGE_AD_FREE_CARD: 'dwellsy_edge_ad_free_card',
  DWELLSY_EDGE_WALKING_CARD: 'dwellsy_edge_walking_card',
  DWELLSY_EDGE_INFO_MODAL: 'dwellsy_edge_info_modal',
  DWELLSY_EDGE_AD_SEARCH_CARD: 'dwellsy_edge_ad_search_card',
  DWELLSY_EDGE_BILLING_PAGE: 'dwellsy_edge_billing_page',

  COMP_REPORT_PAYMENT_BUTTON: 'comp_report_payment_button',
};

export default LOCATIONS;
