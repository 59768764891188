import { useFeature } from '@growthbook/growthbook-react';
import ProfileProgressHero from 'components/account/ProfileProgressHero';
import Link from 'components/common/CustomLink';
import { useAuth } from 'hooks/auth/useAuth';
import React from 'react';
import { useFavorites } from 'store/store';

function StyledLink({ href, text, onClick = () => {} }) {
  return (
    <Link
      onClick={() => onClick()}
      className="font-semibold text-primary-purple block my-6"
      href={href}
    >
      {text}
    </Link>
  );
}

function LoggedInMenu() {
  const auth = useAuth();

  const { clearHistory } = useFavorites((state) => state);

  return (
    <div className="px-6 py-0">
      <StyledLink href="/account/" text="My Profile" />
      <StyledLink
        href={
          auth?.user?.edge_subscribed
            ? '/account/dwellsy-edge'
            : '/dwellsy-edge'
        }
        text="Dwellsy Edge&trade;"
      />
      <StyledLink href="/account/favorites" text="My Favorites" />
      <StyledLink
        href=""
        onClick={() => {
          clearHistory();
          auth.signOut();
          window.location.href = '/';
        }}
        text="Sign Out"
      />
    </div>
  );
}

export default function AvatarMenu({ show = false }) {
  const auth = useAuth();

  const midProfileProgressMessageV2 = useFeature(
    'midprofileprogressmessagev2',
  )?.value;
  const lowProfileProgressMessageV2 = useFeature(
    'lowprofileprogressmessagev2',
  )?.value;
  const completeProfileProgressMessageV2 = useFeature(
    'completeprofileprogressmessagev2',
  )?.value;

  const thresholdOne = 40;
  const thresholdTwo = 99;

  let message = '';
  if (auth?.user?.profile_completeness < thresholdOne) {
    message = lowProfileProgressMessageV2?.message;
  } else if (
    auth?.user?.profile_completeness >= thresholdOne &&
    auth?.user?.profile_completeness <= thresholdTwo
  ) {
    message = midProfileProgressMessageV2?.message;
  } else message = completeProfileProgressMessageV2?.message;

  const buttonText =
    auth?.user?.profile_completeness < thresholdOne
      ? 'Fix your profile'
      : 'Finish your profile';

  if (show === false) return null;

  return (
    <div className="absolute bg-white w-80 shadow-2xl right-0 rounded-lg select-none">
      <div className="relative bg-gradient-to-r from-gray-100 to-slate-100">
        <div className="p-4 bg-[url('/images/graphic-fillout-form.png')] bg-right-bottom h-56 bg-[size:186px] bg-no-repeat border-b border-purple-100">
          <div className="text-primary-purple font-bold pt-2 text-lg mb-2">
            <Link href="/account">Profile Completeness</Link>
          </div>
          {auth?.user?.profile_completeness <= thresholdTwo ? (
            <ProfileProgressHero
              progress={auth?.user?.profile_completeness}
              showMessage={false}
            />
          ) : null}
          <div
            className={`text-black mt-2 ${
              auth?.user?.profile_completeness <= thresholdTwo
                ? 'text-xs'
                : 'text-xl font-semibold'
            }`}
          >
            {message}
          </div>
          {auth?.user?.profile_completeness <= thresholdTwo ? (
            <div className="rounded-full bg-primary-purple text-purple-10 px-4 py-2 mt-10 max-w-fit">
              <Link href="/account">{buttonText}</Link>
            </div>
          ) : null}
        </div>
      </div>
      {auth.loggedIn ? <LoggedInMenu /> : null}
    </div>
  );
}
